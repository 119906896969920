<template>
    <div class="row cham-cong">
        <div class="col-md-12 col-xl-12 align-items-center">
<!--            <el-row :gutter="20" class="mb-g search-thong-bao">-->
<!--                    <div class="">-->
<!--                    <div class="">-->
<!--                        <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="5" class="mt-2">-->
<!--                            <edutalk-date-picker class="w-100"-->
<!--                                 v-model="form.selectDate"-->
<!--                                 type="daterange"-->
<!--                                 popper-class="edutalk-range no-feature"-->
<!--                                 @focus="focus"-->
<!--                                 ref="picker"-->
<!--                                 @changeMonth="onchangeMonth"-->
<!--                                 @change="changeFirstTable"-->
<!--                                 @changeYear="onchangeMonth"-->
<!--                                 :picker-options="pickerOptions"-->
<!--                                 :unlink-panels="true"-->
<!--                                 format="yyyy-MM-dd"-->
<!--                                 range-separator="-"-->
<!--                                 start-placeholder="Từ ngày"-->
<!--                                 end-placeholder="Đến ngày">-->
<!--                            </edutalk-date-picker>-->
<!--                    </el-col>-->
<!--                    </div>-->
<!--                    </div>-->
<!--                <el-col :xs="24" :sm="8" :md="4" :lg="3" :xl="2" class="mt-2">-->
<!--                    <el-button-->
<!--                            class="w-100"-->
<!--                            type="primary"-->
<!--                            @click="searchPayslip"-->
<!--                            icon="el-icon-search"-->
<!--                    >Tìm kiếm-->
<!--                    </el-button-->
<!--                    >-->
<!--                </el-col>-->
<!--            </el-row>-->
            <div class="row">
                <div class="col-lg-12 col-xl-3 order-lg-1 order-xl-1">
                    <!-- profile summary -->
                    <div class="card mb-g rounded-top">
                        <div class="d-flex flex-row pb-3 pt-4 text-center border-top-0 border-left-0 border-right-0">
                            <h4 class="mb-0 flex-1 text-dark fw-500">Chấm công nhân viên</h4>
                        </div>
                        <div class="row no-gutters row-grid">
                            <div class="col-12 p-4">
                                <h5 class="mb-3  col-xs-6">
                                    <span class="d-inline-block width-size">Họ và tên: </span><span class="fw-700">{{ currentUser.name || (currentUser.user && currentUser.user.name) }}</span>
                                </h5>
                                <h5 class="mb-3 col-xs-6">
                                    <span class="d-inline-block width-size">Mã nhân sự:</span><span class="fw-700">{{  (currentUser.id || currentUser.user.id) + 100000  }}</span>
                                </h5>
                                <h5 class="mb-3 col-xs-6">
                                    <span class="d-inline-block width-size">Văn Phòng: </span><span class="fw-700">{{  office }}</span>
                                </h5>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="col-lg-12 col-xl-9 order-lg-3 order-xl-2">
                    <div class="panel">
                        <div class="panel-hdr">
                            <h2>
                                Lịch sử chấm công
<!--                                ( <b class="mr-2">{{ $moment(this.form.selectDate[0]).format('YYYY-MM-DD') }}</b>  đến  <b class="ml-2">{{ $moment(this.form.selectDate[1]).format('YYYY-MM-DD') }}</b> )-->
                            </h2>
                        </div>
                        <div class="panel-container show">
                            <div class="panel-content">
                              <h5 v-if="!loading">Số giờ làm thực tế: <b>{{ totalHourWork }}</b></h5>
                              <h5 v-if="!loading">Số ngày đi làm thực tế: <b>{{ totalDayWork }}</b></h5>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12" v-if="isPC">
                                        <edutalk-date-calendar v-loading="loading" v-model="form.date" @input="selectDate">
                                            <!-- Use 2.5 slot syntax. If you use Vue 2.6, please use new slot syntax-->
                                            <template slot="dateCell" slot-scope="{data}" class="relative">
                                                <div class="absolute inset-0 p-2" :class="classObject(data)">
                                                    <p :class="data.isSelected ? 'is-selected' : ''">
                                                         <b>{{  data.day.split('-').slice(2).join('-') }} {{ data.isSelected ? '' : ''}}</b>
                                                        <br>
                                                        <span v-if="data.day in tableData">{{  tableData[data.day]['in'] }} - {{ (tableData[data.day]['out'] ? tableData[data.day]['out'] : '...') }}</span>
                                                    </p>
                                                </div>
                                            </template>
                                        </edutalk-date-calendar>
                                    </div>
                                    <div class="col-lg-12 col-md-12" v-else>
                                        <edutalk-calendar @update:from-page="eventChangeMonth" :from-page="{ month: parseInt(form.isDateMobile.month), year: parseInt(form.isDateMobile.year) }" is-expanded :attributes='attrsMobile' @dayclick="onDayClick"></edutalk-calendar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
    import {TIME_KEEPING} from '@/core/services/store/service.module';
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    import moment from 'moment-timezone'
    const _ = deepdash(lodash);
    export default {
        components: {
        },
        data() {
            return {
                moment: moment,
                data: {},
                loading: true,
                totalTimeWork: 0,
                totalDayWork: 0,
                totalHourWork: 0,
                typeData: 1,
                isPC: true,
                date: ['',''],
                test : '2019-03-04',
                tableData: [],
                currentDate : new Date(),
                minDate: null,
                maxDate: null,
                window: {
                    width: window.innerWidth
                },
                startOfMonth: null,
                endOfMonth: null,
                form: {
                    selectDate: '',
                    date: new Date(),
                    isDateMobile: {
                        month: ((new Date()).getMonth()),
                        year: (new Date()).getFullYear()
                    },
                },
                paging: {
                    current_page: 1,
                    per_page: 15,
                    total: 0,
                },
                modelConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD', // Uses 'iso' if missing
                },
                attrsMobile: [],
                pickerStartOptions: {
                    disabledDate: this.disabledStartDate
                },
                pickerEndOptions: {
                    disabledDate: this.disabledEndDate
                },
                pickerOptions: {
                    disabledDate: this.disabledDate,
                    onPick: this.onPick
                },
                office :""
            };
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            pickStart(date) {
                this.fromDate = null;
                if (date) {
                    this.fromDate = new Date(date);
                }
            },
            selectDate(data) {
                let date = new Date(data);
                let year = date.getFullYear(), month = date.getMonth();
                this.startOfMonth = new Date(year, month, 1);
                this.endOfMonth = new Date(year, month + 1, 0);
                let params = {
                    from: this.$moment(date).startOf('month').format('YYYY-MM-DD'),
                    to:this.$moment(date).endOf('month').format('YYYY-MM-DD')
                };
                this.queryServer(params);
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.isPC = this.window.width >= 768;
            },
            classObject(data) {
                let dataObject = [];
                let tableData = this.tableData;
                if (data.day in tableData) {
                    let bg_date_danger = (tableData[data.day]['in'] === '' || !tableData[data.day]['out'])
                    let warning = false;
                    if (this.typeData === 1) {
                        warning = false;
                    } else if ((tableData[data.day]['in'] && tableData[data.day]['in']  >= '08:45:00') && (tableData[data.day]['in']  < '12:00:00')) {
                      warning = true
                    }
                    else if ((tableData[data.day]['in'] && tableData[data.day]['in']  >= '13:45:00')) {
                      warning = true
                    }
                    let bg_date_success = (tableData[data.day]['in'] && tableData[data.day]['out'])
                    dataObject =  {
                        'bg-date-danger': bg_date_danger,
                        'bg-date-success': bg_date_success && !warning,
                        'bg-date-warning': bg_date_success && warning
                    }
                }
                return dataObject;
            },
            pickEnd(date) {
                this.toDate = null;
                if (date) {
                    this.toDate = new Date(date);
                }
            },
            eventChangeMonth(date) {
                let startOfMonth = new Date(date.year, date.month, 0);
                let endOfMonth = new Date(date.year, date.month, 0);
                let params = {
                    from: this.$moment(startOfMonth).startOf('month').format('YYYY-MM-DD'),
                    to:this.$moment(endOfMonth).startOf('endOf').format('YYYY-MM-DD')
                };
                this.queryServer(params);
            },
            onDayClick(day) {
                const h = this.$createElement;
                if (day.id in this.tableData) {
                    this.$msgbox({
                        title: `Chi tiết chấm công`,
                        message: h('p', null, [
                            h('span', null, `Ngày chấm công  ${moment(day.id).format('DD-MM-YYYY')}`),
                            h('p', null, [
                                h('span', { style: 'color: #472f92' }, ` Giờ vào:`),
                                h('span', null, ` ${this.tableData[day.id]?.in}`),
                            ]),
                            h('p', null, [
                                h('span', { style: 'color: #472f92' }, ` Giờ ra:`),
                                h('span', null, ` ${this.tableData[day.id].out ?? '...'}`),
                            ]),
                        ]),
                        showCancelButton: true,
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        customClass: 'info-cham-cong'
                    });
                }
            },
            disabledStartDate(date) {
                if (this.toDate) {
                    return this.toDate < date
                }
                return date > new Date();
            },
            disabledEndDate(date) {
                if (this.fromDate) {
                    return this.fromDate > date || date > new Date();
                }
                return date > new Date();
            },
            disabledDate(date) {
                let endOfCurrentMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
                let endMonth = this.endOfMonth > endOfCurrentMonth ? endOfCurrentMonth : this.endOfMonth;
                if (this.minDate) {
                    return this.startOfMonth > date || date > endMonth;
                }
                return date > endOfCurrentMonth;
            },

            onPick({maxDate, minDate}) {
                this.minDate = minDate;
                this.maxDate = maxDate;
                if (this.minDate) {
                    let date = new Date(this.minDate);
                    let year = date.getFullYear(), month = date.getMonth();
                    this.startOfMonth = new Date(year, month, 1);
                    this.endOfMonth = new Date(year, month + 1, 0);
                }
            },
            handleCurrentChange(val) {
                let params = {
                    page: val
                };
                this.queryServer(params);
            },
            queryServer(customProperties) {
                this.loading = true;
                let params = {};
                let date = new Date();
                let year = date.getFullYear(), month = date.getMonth();
                this.startOfMonth = new Date(year, month, 1);
                this.endOfMonth = new Date(year, month + 1, 0);
                let minDate = this.$moment().startOf('month').format('YYYY-MM-DD');
                let maxDate = this.$moment().endOf('month').format('YYYY-MM-DD');
                params = _.merge(params, {from: minDate, to: maxDate});
                params = _.merge(params, customProperties);
                this.form.date = params.from
                this.form.isDateMobile = { month: this.$moment(params.from).format('MM'),
                    year: this.$moment(params.to).format('YYYY')
                }
                this.$store.dispatch(TIME_KEEPING, params).then((response) => {
                    if (response) {
                        if (response.status === 422) {
                            this.$message({
                                type: 'warning',
                                message: `${response.message}`,
                                showClose: true,
                            });
                        } else {
                            this.totalDayWork = response.data.days;
                            this.totalHourWork = response.data.total_hours;
                            this.typeData = response.data.user.type;
                            this.tableData = this.convertData(response.data.data.data);
                            let office_res = response.data.user.office == 0 ? "Trụ sở chính" : "Trần Đại Nghĩa";
                            this.office = office_res;
                        }
                    }
                    this.loading = false;
                });
            },
            totalTime () {

            },
            convertData: function (data) {
                let dataCheckin = [];
                let mobileData = [];
                let time = 0;
                _.map(data, (value) => {
                    dataCheckin[`${value.date_format}`] = { in: value?.in, out: value?.out};
                    let warning = false;
                    let timeByDay = 0;
                    if (value.in && value.out) {
                        let timeIn = `${value.in}` < '08:00:00' ? '08:00:00' : `${value.in}`;
                        let timeOut = `${value.out}` > '19:00:00' ? '19:00:00' : `${value.out}`;
                        timeByDay = new Date(`2022-2-22 ${timeOut}`) - new Date(`2022-2-22 ${timeIn}`);
                    }
                    if (this.typeData === 1) {
                      warning = false;
                    } else if (value.in && value.in  >= '08:45:00' && value.in < '12:00:00' ) {
                        if (value.out) {
                            let timeOut = `${value.out}` > '19:00:00' ? '19:00:00' : `${value.out}`;
                            timeByDay = new Date(`2022-2-22 ${timeOut}`) - new Date('2022-2-22 13:30:00');
                        }
                        warning = true
                    }
                    else if (value.in && value.in  >= '13:45:00') {
                      if (value.out) {
                        let timeOut = `${value.out}` > '19:00:00' ? '19:00:00' : `${value.out}`;
                        timeByDay = new Date(`2022-2-22 ${timeOut}`) - new Date('2022-2-22 13:30:00');
                      }
                      warning = true
                    }
                    mobileData.push({
                        bar: (value.in && value.out ? (warning ? 'red' : 'purple') : 'yellow'),
                        dates: new Date(value.date_format),
                    })
                    time += timeByDay;
                })
                this.totalTimeWork = (time / 1000 / 3600 / 8).toFixed(2);
                this.attrsMobile = mobileData;
                return dataCheckin;
            },
            searchPayslip: function () {
                let params = {};
                this.queryServer(params);
            },
            onchangeMonth(item) {
                let date = new Date(item.date);
                let year = date.getFullYear(), month = date.getMonth();
                let startOfMonth = new Date(year, month, 1);
                let endOfMonth = new Date(year, month + 1, 0);
                this.form.selectDate = [startOfMonth, endOfMonth];
            },
            changeFirstTable(item) {
                this.form.selectDate = item;
            },
            focus() {
                this.minDate = null;
                this.mountedDate();
            },
            mountedDate() {
                if (!this.form.selectDate) {
                    let date = new Date();
                    let year = date.getFullYear(), month = date.getMonth();
                    let startOfMonth = new Date(year, month, 1);
                    let endOfMonth = new Date(year, month + 1, 0);
                    this.form.selectDate = [startOfMonth, endOfMonth];
                    this.form.date = startOfMonth
                    this.form.isDateMobile = { month: month, year: year}
                }
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: 'Chấm công', icon: 'fal fa-money-bill'},
            ]);
            this.mountedDate();
            this.queryServer();
        },
        computed: {
            ...mapGetters(['currentUser']),
        },
    };
</script>
<style>
.el-button-group .el-button--mini:nth-child(2) span {
    display: none;
}
.el-button-group .el-button--mini:nth-child(2):after {
    content: 'Tháng này';
}
</style>
